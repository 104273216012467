document.addEventListener("turbolinks:load", function() {
    // Remove existing Typeform script
    const existing_typeform_script_tag = document.querySelector("[src='https://embed.typeform.com/next/embed.js']");
    if (existing_typeform_script_tag) existing_typeform_script_tag.remove();

    // Reload Typeform script
    const script = document.createElement('script');
    script.src = "https://embed.typeform.com/next/embed.js";
    script.async = true;
    document.head.appendChild(script);
});